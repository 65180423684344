const WORKS_WITH = [
  {
    id: 1,
    title: "Startups",
    icon: "/assets/imgs/our-clients/startups.png",
    description:
      "We work with young teams, providing support at all stages of development and growth, from the idea to the first users.",
  },
  {
    id: 2,
    title: "Full-fledged development projects",
    icon: "/assets/imgs/our-clients/development.png",
    description:
      "A full development cycle for projects of any complexity, from concept to launch and support.",
  },
  {
    id: 3,
    title: "Corporate clients",
    icon: "/assets/imgs/our-clients/clients.png",
    description:
      "We create solutions for the automation and optimization of business processes for large companies and organizations.",
  },
  {
    id: 4,
    title: "Small, medium and large businesses",
    icon: "/assets/imgs/our-clients/business.png",
    description:
      "Support for both large businesses and start-up entrepreneurs in the creation and modernization of web and mobile applications, improving customer experience.",
  },
  {
    id: 5,
    title: "E-commerce",
    icon: "/assets/imgs/our-clients/e-comerce.png",
    description:
      "Development of online stores, sales platforms and integration with various payment systems.",
  },
  {
    id: 6,
    title: "Fintech",
    icon: "/assets/imgs/our-clients/fintech.png",
    description:
      "We implement fintech solutions, from personal accounts to the integration of complex financial instruments.",
  },
  {
    id: 7,
    title: "Education and EdTech",
    icon: "/assets/imgs/our-clients/education.png",
    description:
      "We create educational platforms and applications for online learning, interactive content and training.",
  },
  {
    id: 8,
    title: "Health Tech",
    icon: "/assets/imgs/our-clients/health.png",
    description:
      "Development of products for the medical industry: platforms for telemedicine, patient data management and medical consultations.",
  },
  {
    id: 9,
    title: "Marketing and Analytics",
    icon: "/assets/imgs/our-clients/analytics.png",
    description:
      "Tools and platforms for tracking, analyzing and optimizing marketing campaigns.",
  },
];

export default WORKS_WITH;
