const TECHNOLOGIES = [
  {
    src: "/assets/svgs/technologies/front-end-technology.svg",
    title: "Front-End:",
    text: "Next.js & React & Typescript Frameworks Javascript (ES6 - ES15) / HTML / CSS (SCSS, LESS, Bootstrap, Material UI, Tailwind)",
  },
  {
    src: "/assets/svgs/technologies/management-technology.svg",
    title: "Application Life Cycle Management:",
    text: "Jira / TFS / Trello / Business models (B2B & B2C) / Confluence / Wiser / Release MGMT / SDLC / Delivery MGMT / Understanding of PaaS & SaaS & IaaS & IaC",
  },
  {
    src: "/assets/svgs/technologies/testing-technology.svg",
    title: "Testing:",
    text: "dotnet test (VStest and MStest) / Specflow / Junit (XUnit /NUnit) / Cucumber / TestCafe",
  },
  {
    src: "/assets/svgs/technologies/back-end-technology.svg",
    title: "Back-End:",
    text: "Node.js & Nest.js (Express.js & Meteor.js)",
  },
  {
    src: "/assets/svgs/technologies/dbs-technology.svg",
    title: "DBs:",
    text: "PostgreSQL / MongoDB / MySQL / DynamoDb / Elasticsearch / Redis",
  },
  {
    src: "/assets/svgs/technologies/scm-vcs-technology.svg",
    title: "SCM/VCS:",
    text: "Git / Branching models (e. g. Git Flow) / Bitbucket / GitHub / Gitlab / TFS",
  },
  {
    src: "/assets/svgs/technologies/patterns-technology.svg",
    title: "Development general knowledge:",
    text: "OOP & OOD / TDD & BDD / API (REST API & Postman & Swagger & API Doc)",
  },
  {
    src: "/assets/svgs/technologies/cloud-technology.svg",
    title: "Cloud Solutions:",
    text: "AWS / Azure / GCP / Hetzner / Vercel / DigitalOcean / Linode / OVHcloud",
  },
  {
    src: "/assets/svgs/technologies/general-technology.svg",
    title: "General:",
    text: "Windows Server 2016 & 2019 & 2022 / Linux RedHat & Ubuntu / IIS / Monolith & Microservice App architecture / Docker / Kubernetes",
  },
];

export default TECHNOLOGIES;
