import React from "react";
import { Link } from "react-router-dom";
import "./PortfolioItem.scss";

const PortfolioItem = ({ src, title, urlName, driveGoogle }) => {
  return (
    <>
      <div className="portfolio-item__container">
        <Link
          to={
            driveGoogle === undefined
              ? {
                  pathname: `/portfolio/${urlName}`,
                  state: { modal: true },
                }
              : { pathname: driveGoogle }
          }
          className="portfolio-item__link"
          target={driveGoogle ? "_blank" : undefined}
        >
          <div className="portfolio-item__img">
            <img src={src} alt={title} />
          </div>

          <h2 className="portfolio-item__title">{title}</h2>

          <button type="button" className="portfolio-item__details">
            Details
          </button>
        </Link>
      </div>
    </>
  );
};

export default PortfolioItem;
