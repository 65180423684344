import WORKS_WITH from "./works-with";

const SERVICES = [
  {
    id: 1,
    name: "Full-Stack Development",
    description:
      "Comprehensive creation of projects from scratch to deployment, tailored to any topic or industry.",
    img: "/assets/imgs/services/development.jpg",
  },
  {
    id: 2,
    name: "UI/UX Design",
    description:
      "Development of intuitive and visually appealing layouts for websites, apps, and business concepts.",
    img: "/assets/imgs/services/ui-ux.jpg",
  },
  {
    id: 3,
    name: "Project Management",
    description:
      "Effective team leadership, resolution of challenges, and delivering projects to successful completion.",
    img: "/assets/imgs/services/management.jpg",
  },
  {
    id: 4,
    name: "QA & Bug Fixing",
    description:
      "In-depth testing and debugging of existing projects to ensure quality and stability.",
    img: "/assets/imgs/services/quality.jpg",
  },
  {
    id: 5,
    name: "Rewrite & Optimization",
    description:
      "Modernizing legacy projects with JavaScript and its frameworks, enhancing performance, animations, and backend reliability.",
    img: "/assets/imgs/services/optimization.jpg",
  },
];

export default SERVICES;
